<template>
    <admin-dashboard-layout>
        <router-link
            :to="{name: 'admin.pages.sections.create'}"
        >
            <v-btn
                color="black"
                large
            >
                <v-icon color="green">mdi-plus-outline</v-icon>
                <span>{{ $t('Add Page Section') }}</span>
            </v-btn>
        </router-link>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="sections"
            :items-per-page="30"
            class="elevation-1"
            @click:row="handleClickRow"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at ? moment(item.created_at).format('LLL') : '' }}
            </template>
            <template v-slot:item.action="{ item }">
                <v-btn @click.stop="handleDeleteItem(item)">
                    <v-icon color="red">mdi-trash-can-outline</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </admin-dashboard-layout>
</template>

<script>
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import PageSection from "@/models/admin/PageSection";

export default {
    name: "faq-sections-index",
    components: {
        AdminDashboardLayout,
    },
    data: function () {
        return {
            loading: false,
            sections: [],
            headers: [
                {text: 'Name', value: 'name'},
                {text: 'Page', value: 'page'},
                {text: 'Order', value: 'order'},
                {text: 'Created at', value: 'created_at'},
                {text: 'Action', value: 'action'},
            ],
        }
    },
    methods: {
        handleClickRow({id}) {
            this.$router.push({name: 'admin.pages.sections.edit', params: {id}})
        },
        handleDeleteItem({id}) {
            this.loading = true
            PageSection.find(id)
                .then(m => {
                    m.delete()
                    this.fetchPageSections()
                }).finally(() => this.loading = false)
        },
        fetchPageSections() {
            this.loading = true
            PageSection
                .get()
                .then(sections => this.sections = sections)
                .catch((e) => console.log(e))
                .finally(() => this.loading = false)
        }
    },
    created() {
        this.fetchPageSections()
    },
}
</script>

<style scoped>

</style>
